import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { Check } from "react-feather"
import ReactMarkdown from "react-markdown"
import remarkGfm from "remark-gfm"
import SiteImage from "../components/SiteImage"
import Layout from "../components/Layout"
import Seo from "../components/SEO"

const ServiceCategoryTemplate = ({ pageContext: { serviceCategory } }) => {
  const query = graphql`
    query ServiceSectionQuery {
      strapiServiceSection {
        data {
          attributes {
            specializationsTitle
            serviceListTitle
          }
        }
      }
    }
  `
  const {
    strapiServiceSection: {
      data: {
        attributes: {
          specializationsTitle,
          serviceListTitle,
        },
      },
    },
  } = useStaticQuery(query)
  return (
    <Layout>
      <Seo
        title={
          serviceCategory.pageContent.seoTitle ||
          `${serviceCategory.title} - Doganbros.com`
        }
        description={
          serviceCategory.pageContent.seoDescription ||
          `${serviceCategory.description}`
        }
        keywords={
          serviceCategory.pageContent.seoKeywords ||
          serviceCategory.specializations.map(b => b.title).join(",")
        }
      />
      <section id="service-category-template" className="section">
        <div className="container mb-6 has-text-centered">
          <h3 className="title has-text-primary mb-6">
            {serviceCategory.title}
          </h3>
          <div>
            <div className="content block has-text-left">
              <ReactMarkdown remarkPlugins={[remarkGfm]}>
                {serviceCategory.pageContent.content}
              </ReactMarkdown>
            </div>
            {serviceCategory.specializations && (
              <div className="is-flex is-flex-direction-column is-align-items-center">
                <div className="title is-5 my-6">{specializationsTitle}</div>
                <ul className="block has-text-left">
                  {serviceCategory.specializations.map(
                    ({ id, title: specializationTitle }) => (
                      <li className="my-1" key={id}>
                        <span className="has-text-primary icon svg-inline-icon">
                          <Check />
                        </span>
                        <span>{specializationTitle}</span>
                      </li>
                    )
                  )}
                </ul>
              </div>
            )}
            {serviceCategory.services.data.length > 0 && (
              <div className="block my-6">
                <div className="title is-4">{serviceListTitle}</div>

                <div className="columns is-multiline is-centered">
                  {serviceCategory.services.data.map(
                    ({ attributes: service, id }) => (
                      <div
                        key={id}
                        className="column is-half-tablet is-one-third-desktop is-flex"
                      >
                        <div className="box is-flex is-flex-grow-1 is-flex-direction-column">
                          <div className="title-container">
                            <div className="title is-4 has-text-centered">
                              {service.title}
                            </div>
                            <div className="subtitle is-6 has-text-centered my-5">
                              {service.description}
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ServiceCategoryTemplate