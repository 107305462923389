import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

const SiteImage = ({ image, ...props }) => {
  return image.data.attributes.ext === ".svg" ? (
    <img src={image.data.attributes.localFile.url} {...props} />
  ) : (
    <GatsbyImage
      image={image.data.attributes.localFile.childImageSharp.gatsbyImageData}
      {...props}
    />
  )
}

export default SiteImage
